// IMPORTS
@import "../base/settings";

.comp.director-card-section {
    margin: 60px 0;
    padding: 60px 0;
    background-color: $blue;
    color: #fff;
    overflow: hidden;
    position: relative;

    .container-lg {
        position: relative;
        z-index: 1;
    }

    h2, h3, h4, p, a {
        color: #fff;
    }

    img.arrow-pattern {
        opacity: .15;
        @include cover-image;
        mix-blend-mode: soft-light;
        left: unset;
        right: -22%;
        z-index: 0;
    }

    h3.director-card-heading {
        padding-bottom: 12px;
        border-bottom: 1px solid;
        border-color: rgba(255,255,255,0.5);
    }

    .director-title {
        @media #{$above991} {
            padding-left: 20px;
        }
    }

    .director-image {
        border-radius: 100%;
        padding-top: 100%;
        max-width: 200px;
        max-height: 200px;
        margin: auto;
        overflow: hidden;
        border: 5px solid #fff;
        position: relative;
        @include standard-box-shadow($size:10px);

        img {
            @include cover-image;
        }

        @media #{$below768} {
            padding-top: 0;
            width: 200px;
            height: 200px;
        }
    }
}