// IMPORTS
@import "../base/settings";

.comp.fifty-fifty-section {
    padding: 30px 0;
    margin: 0;
    /*border-bottom: 1px solid $lightgrey;*/

    .fifty-image {
        padding-top: 66.625%;
        position: relative;
        overflow: hidden;
        margin-bottom: 30px;

        img {
            @include cover-image;
        }

        &:before, &:after {
            display: block;
            content: "";
            position: absolute;
            z-index: 2;
            transform: rotate(45deg);
        }

        &:before {
            background-color: #fff;
            width: 20px;
            height: 315px;
            bottom: -70px;
            right: 72px;
        }

        &:after {
            background-color: $orange;
            width: 263px;
            height: 245px;
            bottom: -138px;
            right: -147px;
            opacity: .7;
        }

        @media #{$above768} {
            margin-bottom: 0;
        }
    }

    .fifty-content {
        @include vertical-align;

        h2 {
            margin-top: 0;
        }

        @media #{$above768} {
            padding-left: 65px;
        }
    }

    .order-md-first {
        .fifty-content {
            @media #{$above768} {
                padding-left: 0;
                padding-right: 65px;
            }
        }
    }

    .order-md-last {
        .fifty-image {
            &:before, &:after {
                transform: rotate(-45deg);
            }

            &:before {
                left: 72px;
                right: unset;
            }

            &:after {
                background-color: $magenta;
                left: -147px;
                right: unset;
            }
        }
    }
}