// IMPORTS
@import "../base/settings";

.opportunities-list-page {
    .portal-dropdown {
        margin-bottom: 40px;

        button.portal-dropdown-toggle.for-programs {
            width: 100%;
            background-color: $orange;
            text-transform: none;

            .portal-drop-arrow {
                background-color: #ca5326;
            }
        }
    }
}

.dropdown-menu.program-dropdown-content {
    width: 100%;
    margin: 0;
    padding: 0;
    border-radius: 0;
}

ul.select-list {
    margin: 0;
    padding: 0;

    li {
        a {
            display: block;
            padding: 15px;
            color: $orange;
            border-top: 1px solid $lightgrey;

            &:hover, &:focus {
                background-color: $lightgrey;
            }
        }

        &:first-child a {
            border-top: 0;
        }
    }
}

.career-listing-item {
    margin-bottom: 40px;

    .program-label {
        font-family: 'Aleo', serif;
        font-size: .9em;
        color: #fff;
        display: inline-block;
        padding: 4px 20px;
        background-color: $orange;
    }

    h2 a {
        .fa-solid {
            margin-left: 10px;
            @include transition();
        }

        &:hover, &:focus {
            .fa-solid {
                margin-left: 20px;
            }
        }
    }

    .listing-meta {
        font-size: .9em;
        border-top: 1px solid $lightgrey;
        border-bottom: 1px solid $lightgrey;
    }

    .listing-content {
        padding: 20px 0;

        p {

        }
    }
}

dl.meta-list {
    @media #{$above991} {
        @include flex($wrap:nowrap);
    }

    dt {
        align-self: flex-start;
        min-width: unset;
        margin-right: 10px;
        margin-bottom: 0;
    }

    dd {
        align-self: flex-start;
        padding: 5px;
        margin-right: 20px;
        margin-bottom: 0;
    }
}