// IMPORTS
@import "settings";

.ctuir-desktop-nav {
    display: none;

    a.topnav-link {
        display: block;
        font-weight: 500;
        color: #fff;
        font-size: .8em;
        margin-left: 10px;
        padding-bottom: 2px;
        border-bottom: 5px solid transparent;

        &:after {
            @include transition;
            display: block;
            content: "";
            position: relative;
            bottom: 0;
            height: 5px;
            background-color: $magenta;
            width: 0;
        }

        &:first-child {
            margin-left: 0;
        }

        &:hover, &:focus {
            &:after {
                width: 100%;
            }
        }

        @media #{$above1200} {
            font-size: .9em; 
            margin-left: 20px;
        }

        @media #{$above1400} {
            font-size: 1em; 
            margin-left: 30px;
        }
    }

    @media #{$above1200} {
        @include flex($wrap:nowrap, $justify:flex-end);
        padding-top: 46px;
    }
}

.no-hero {
    .ctuir-desktop-nav {
        a.topnav-link {
            color: $bodytext;
        }
    }

    .hamburger {
        .hamburger-inner {
            background-color: $bodytext;

            &:before, &:after {
                background-color: $bodytext;
            }
        }
    }
}

a.hamburger {
    display: block;
    margin: 0 0 0 30px;
    padding: 0;
    z-index: 10;

    .hamburger-inner {
        background-color: #fff;

        &:before, &:after {
            background-color: #fff;
        }
    }

    &:focus {
        box-shadow: none!important;
    }

    @media #{$above1200} {
        display: none;
    }
}

.mm-menu {
    height: 100vh;
    background: #fff;
    border-color: #051c2c;
    --mm-listitem-size: 70px;
    --mm-color-text: #051c2c;
    --mm-color-icon: #051c2c;

    .mm-navbars--top {
        border-bottom: 1px solid $darkblue;

        .mm-navbar {
            height: 80px;
            text-transform: uppercase;
            background-color: $darkblue;
            color: #fff;
        }
    }

    #cludo-search-form {
        padding: 10px 30px;
        position: relative;
        @include flex();

        input.mobile-search-input {
            border-radius: 30px;
            background-color: rgba(0,0,0,0.2);
            color: #fff;
            width: 100%;
            border: 0px;
            padding: 12px 12px 12px 70px;
        }

        input.mobile-search-input::placeholder {
            color: #fff;
        }

        button.mobile-search-mag {
            position: absolute;
            border: 0;
            outline: none;
            color: $blue;
            background-color: transparent;
            left: 50px;
        }
    }

    .mm-listitem__text {
        padding-left: 30px;
        padding-right: 30px;
    }

    .mm-listitem {
        font-size: 1.2em;
        font-weight: 400;
        color: #051c2c;

        &:after {
            left: 0;
        }

        .mm-listitem__btn {
            border-left: 0;
        }

        .mm-btn--next:after {
            width: 16px;
            height: 16px;
            border-width: 2px;
        }
    }

    .mm-searchfield {
        height: 80px;

        input {
            height: 50%;
            background-color: #fff;
            border-radius: 0;
            margin-bottom: 0;
            border: 1px solid $lightgrey;
        }
    }

    .search_autocomplete {
        display: none;
    }

    .box-search {
        width: 100%;

        label[for="txtNavSearch"] {
            font-size: .9em;
        }
    }
}