// IMPORTS
@import "../base/settings";

.comp.upcoming-events-section {
    margin: 60px 0;

    .component-heading {
        span.fa-duotone {
            color: $red;
        }
    }

    .events-heading-left {
        @media #{$above991} {
            width: 60%;
        }
    }
}

.flip-card {
    position: relative;
    /*padding: 10px;*/
    padding-top: 52.52%;

    .card-face {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        overflow: hidden;
        background: linear-gradient(0deg, rgba(207,185,217,1) 0%, rgba(255,255,255,1) 100%);
        @include standard-box-shadow($size:10px);
        @include transition($sec:0.3s);
    }

    .flip-icon {
        background-color: $lavender;
        position: absolute;
        top: 0;
        right: 0;
        width: 61px;
        height: 46px;
        font-size: 1.2em;
        @include flex($justify:center);
        align-content: center;
        z-index: 1;
    }

    .flip-card-content {
        padding-top: 52.52%;

        .flip-content-position {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            padding: 10px;
            
            .flip-content-display {
                height: 100%;
                padding: 20px;
                @include standard-box-shadow($size:10px, $opacity:0.4);
            }
        }

        span.flip-date {
            font-weight: 600;
            color: #656565;
            @include fluid-type($min-vw:991px, $max-vw: 1400px, $min-font-size: 14px, $max-font-size: 16px);
        }

        h3.flip-title {
            font-family: 'Roboto', sans-serif;
            /*font-size: 1.4em;*/
            padding-right: 30px;
            max-height: 90px;
            overflow: hidden;
            @include vertical-align(absolute);
            @include fluid-type($min-vw:991px, $max-vw: 1400px, $min-font-size: 19px, $max-font-size: 24px);
        }
    }

    .flip-card-front {
        .flip-content-display {
            background-color: #fff;
        }
    }

    .flip-card-back {
        transform: rotateY(180deg);

        .flip-content-display {
            background-color: $red;
            color: #fff;

            p.flip-summary {
                font-size: .9em;
                font-weight: 600;
            }

            span.hosted-by {
                display: block;
                font-size: .7em;

                @media #{$tabletRange} {
                    display: none;
                }
            }
        }

        a.ctuir-btn {
            position: absolute;
            bottom: 10px;
            left: 10px;

            &:hover, &:focus {
                background-color: $lavender;
                color: $bodytext;
            }

            &:before {
                display: none;
            }
        }
    }

    &:hover .flip-card-back {
        transform: rotateY(0);
    }
    &:hover .flip-card-front {
        transform: rotateY(-180deg);
    }
}

.detail-image {
    position: relative;
    overflow: hidden;
    padding-top: 133%;

    img {
        @include cover-image;
    }
}

.event-top-area {
    padding: 40px 0 60px;
}

.event-details {
    dl {
        margin-top: 0;
    }
}

.event-description {
    padding: 40px 0;
    border-top: 1px solid $lightgrey;
}


/*======== EVENT LIST =========*/

.event-list-page {
    .event-month-heading {
        padding: 8px 20px;
        margin-bottom: 30px;
        color: #fff;
        text-transform: uppercase;
        font-size: 1.1em;
        background-color: $red;
    }

    .event-showing {
        margin-bottom: 30px;

        .text-highlight {
            color: $red;
            font-weight: 700;
        }
    }

    ul.event-list-results {
        margin-top: 30px;

        li.event-result {
            @include flex($wrap:nowrap);
            padding-bottom: 40px;
            border-bottom: 1px solid #e3e3e3;
            margin-bottom: 40px;

            .result-date {
                width: 84px;
                margin-right: 26px;
                flex-shrink: 0;
                align-self: flex-start;
                border: 1px solid #e3e3e3;

                .date-month {
                    text-align: center;
                    font-size: 1.2em;
                    color: #fff;
                    text-transform: uppercase;
                    font-weight: 600;
                    padding: 4px 6px;
                    background-color: $blue;
                }

                .date-day {
                    text-align: center;
                    font-size: 1.6em;
                    color: $blue;
                    font-weight: 600;
                    padding: 4px 6px;
                }
            }

            .result-content {
                h4 {
                    margin-top: 0;
                    margin-bottom: 0;
                    a {
                        color: $blue;
                    }
                }

                .result-meta {
                    display: block;
                    text-transform: uppercase;
                    font-weight: 600;
                }

                p.result-summary {
                    margin: 20px 0;
                }

                .result-hosted {
                    font-weight: 600;
                    display: block;
                    font-size: .8em;
                }

                span.category-tag {
                    display: inline-block;
                    font-size: .8em;
                    margin-top: 12px;
                    padding: 4px 12px;
                    background-color: $lightgrey;
                }
            }
        }
    }
}

.event-list-sidebar {

}

.calendar-section {
    margin-bottom: 30px;
}

.events-filters-section {
    margin-bottom: 30px;

    .filters-heading {
        padding: 8px 20px;
        color: #fff;
        text-transform: uppercase;
        font-size: 1.1em;
        background-color: $red;
    }

    .event-filters {
        padding: 12px;
        background-color: $lightgrey;

        button.event-filters-button {
            background-color: #fff;
            color: $bodytext;
            border: 1px solid #d6d6d6;
            @include flex();
            @include transition;
            align-items: center;

            .drop-label {
                font-weight: 500;
            }

            .drop-arrow {
                background-color: #d6d6d6;
                width: 44px;
                margin-left: auto;
                display: flex;
                height: 100%;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .ctuir-btn {
        margin-top: 12px;
    }
}

#calendar-component-column {
    .vuecal__header {
        .vuecal__title-bar {
            background-color: $blue;
            color: #fff;

            button {
                color: #fff;
            }
        }
    }

    .vuecal__cell {
        margin: 4px 0;

        .vuecal__cell-content {
            border-color: transparent;
            background-color: transparent;

            .vuecal__cell-events-count {
                display: none;
            }
        }

        &.vuecal__cell--today {
            .vuecal__cell-content {
                border-color: $darkblue!important;
                background-color: $blue!important;
            }
        }

        &.vuecal__cell--has-events {
            .vuecal__cell-content {
                background-color: #64b6ff33;
            }
        }
    }


}
