// IMPORTS
@import "../base/settings";

.comp.sixty-forty-section, .comp.blue-pattern-section {
    margin: 60px 0;
    padding: 60px 0;
    background-color: $darkblue;
    color: #fff;
    overflow: hidden;
    position: relative;

    .container-lg {
        position: relative;
        z-index: 1;
    }

    h2, h3, h4, p, a, label {
        color: #fff;
    }

    .sixty-left {
        h2 {
            margin-bottom: 30px;
        }

        @media #{$above991} {
            padding-right: 160px;
        }
    }

    .forty-right {
        height: 100%;

        h3 {
            font-size: 1.2em;
        }

        @media #{$above991} {
            border-left: 1px solid;
            border-color: rgba(255,255,255,0.5);
            padding-left: 40px;
        }
    }

    img.arrow-pattern {
        opacity: .15;
        @include cover-image;
        mix-blend-mode: soft-light;
        left: unset;
        right: -22%;
        z-index: 0;
    }
}

ul.arrow-list {
    margin-top: 30px;

    li {
        a {
            display: inline-block;
            padding: 10px 0;
            border-bottom: 5px solid transparent;

            &:after {
                @include transition();
                display: block;
                content: "";
                position: relative;
                bottom: 0;
                height: 5px;
                background-color: #fff;
                width: 0;
            }

            span {
                display: inline-block;
                margin-right: 12px;
            }

            &:hover, &:focus {
                &:after {
                    width: 100%;
                }
            }
        }
    }
}