// IMPORTS
@import "settings";

.mm-page {
    min-height: calc(var(--vh, 1vh)*100);
}

.ctuir-header {
    color: white;
    position: absolute;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    /*overflow: hidden;*/
    z-index: 10;

    img.dark-text-logo, img.arrow-pattern {
        display: none;
    }

    .container-lg {
        z-index: 1;
        position: relative;
    }

    @media #{$above576} {
        height: 180px;
        padding-bottom: 0;
    }
}

.no-hero {
    .ctuir-header {
        color: $bodytext;
        background-color: $lightgrey;
        position: relative;
        border-bottom: 10px solid $magenta;

        img.dark-text-logo, img.arrow-pattern {
            display: block;
        }

        img.arrow-pattern {
            right: -60%;
        }

        img.white-text-logo {
            display: none;
        }

        .social-icons a.social-icon {
            color: $bodytext;

            &:hover, &:focus {
                color: $magenta;
            }
        }

        a.ctuir-search-btn {
            color: $bodytext;

            &:hover, &:focus {
                color: $magenta;
            }
        }
    }
}


a.logo-link {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    max-width: 174px;
    
    img {
        max-width: 100%;
    }

    @media #{$above576} {
        margin-left: 0;
        margin-bottom: 0;
    }
}

.portal-and-search {
    @include flex($wrap:nowrap);
    justify-content: space-between;
    align-items: flex-end;

    @media #{$above576} {
        justify-content: flex-end;
    }
}

.portal-dropdown {
    .header-social {
        @include flex($wrap:nowrap, $justify:space-between);
        align-items: center;
        margin-bottom: 6px;

        .social-label {
            display: block;
            white-space: nowrap;
            margin-right: 20px;
        }
    }

    .social-icons {
        @include flex($wrap:nowrap, $justify:space-between);
        width: 100%;

        a.social-icon {
            color: #fff;
            font-size: 1.2em;
            display: block;

            &:hover, &:focus {
                color: $magenta;
            }
        }
    }

    button.portal-dropdown-toggle {
        background-color: $magenta;
        padding: 0 0 0 12px;
        border: 0;
        color: #fff;
        width: 277px;
        height: 40px;
        text-transform: none;
        @include flex();
        @include transition;
        align-items: center;

        img.portal-icon {
            display: block;
            margin-right: 8px;
        }

        .portal-drop-label {
            font-weight: 500;
        }

        .portal-drop-arrow {
            background-color: #952570;
            width: 44px;
            margin-left: auto;
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: center;
        }

        &:hover, &:focus {
            /*background-color: #952570;*/
        }
    }
}

.portal-login-dropdown {
    background-color: $lightgrey;
    padding: 12px;

    input[type=password], input[type=text] {
        margin-bottom: 12px;
    }

    .forgot-pw {
        padding: 12px 0;
    }

    .logout-content {
        .logged-in-user {
            padding: 12px 0;

            span.logged-user {
                display: inline-block;
                background-color: $darkblue;
                color: #fff;
                border-radius: 6px;
                padding: 4px 8px;
            }

            a.portal-home-link {
                display: block;
                font-weight: 700;
                margin-top: 15px;
            }
        }
    }
}

a.ctuir-search-btn {
    display: none;
    color: #fff;
    background-color: transparent;
    margin-left: 24px;
    border: 0;
    flex-direction: column;
    justify-content: center;

    .search-label {
        display: block;
        font-weight: 500;
        margin-bottom: 8px;
    }

    span.fa-magnifying-glass {
        @include transition;
        font-size: 2em;
    }

    &:hover, &:focus {
        span.fa-magnifying-glass {
            transform: scaleX(-1);
        }
    }

    @media #{$above1200} {
        display: block;
        @include flex();
    }
}