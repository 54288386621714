// IMPORTS
@import "../base/settings";

.comp.mission-statement-section {
    padding: 60px 0;

    .mission-statement {
        padding: 0 15%;

        p {
            font-size: 1.3em;
            font-weight: 300;
            font-style: italic;
        }
    }
}