// IMPORTS
@import "../base/settings";

a.ctuir-hyperlink {
    text-decoration: underline;
}

.btn.primary {
    border-radius: 0;
    border: 0;

    &:hover, &:focus {
        background-color: #a13881;
    }
}

a.ctuir-btn, button.ctuir-btn, input.ctuir-btn, input[type=submit], .btn.primary {
    background-color: $magenta;
    color: #fff;
    font-size: 1em;
    font-weight: 500;
    line-height: 1.4;
    white-space: nowrap;
    text-transform: uppercase;
    padding: 8px 26px;
    position: relative;
    display: inline-block;
    overflow: hidden;
    border-radius: 0;
    @include transition();

    &.btn-magenta {
        background-color: $magenta;
    }

    &.btn-orange {
        background-color: $orange;
    }

    &.btn-red {
        background-color: $red;
    }

    &.btn-blue {
        background-color: $blue;
    }

    &.btn-white {
        background-color: #fff;
        color: $red;

        &:hover, &:focus {
            color: #fff;
        }

        &:before {
            background-color: rgba(201,22,41,1);
            mix-blend-mode: darken;
        }
    }

    &.btn-grey {
        background-color: #d6d6d6;
        color: $bodytext;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.5);
        mix-blend-mode: overlay;
        transition: left 0.3s ease;
    }

    &:hover, &:focus {

        &:before {
          left: 0;
        }
    }
}

button.ctuir-btn {
    border: 0;
}



