// IMPORTS
@import "../base/settings";

form, .umbraco-forms-form {
    input[type=text], input[type=email], input[type=file], input[type=password], textarea {
        background-color:white;
        border-radius:0;
        width: 100%!important;
        max-width: unset!important;
        border: 0;
        padding: 8px 20px;
        font-size: 1em;
        color: $bodytext;
        border: 1px solid #cccccc;
    }

    input[type=checkbox] {
        width: 12px;
        height: 12px;
    }

    select {
        min-width: 120px;
    }

    .control-group {
        max-width: 660px;
    }

    .form-group {
        margin-bottom: 20px;

        .btn.btn-primary, .ctuir-btn {
            margin-bottom: 12px;
        }
    }

    .form-control {
        appearance: auto;
    }
 
    textarea {
        min-height: 240px;
    }

    legend {
        font-weight: 700;
        font-size: 1.2em;
        padding: 12px 0;
        border-bottom: 2px solid #f0f0f0;
        margin-bottom: 12px;
    }

    h4 {
        margin-bottom: 15px;
    }

    .umbraco-forms-field {
        margin-top: 12px;
    }

    .umbraco-forms-field-wrapper {
        margin-bottom: 30px;
    }

    .umbraco-forms-fieldset {
        margin-bottom: 0;
    }
    
    label {
        color: $bodytext;
        font-weight: 700;
        margin-bottom: 10px;
    }

    .checkboxlist, .radiobuttonlist {
        @include flex();
        align-items: center;

        input[type=checkbox], input[type=radio] {
            margin: 0 8px 0 0;
        }

        label {
            margin: 0 12px 0 0;
        }
    }

    .umbraco-forms-hidden {
        display: none;
    }

    .field-validation-error {
        color: red;
        display: block;
    }
}

.reset-pass {
    margin: 12px 0;
}

.comp.portal-login-section {

}