/*importing bootstrap from node-modules*/
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/bootstrap/scss/variables";

// IMPORTS
@import "base/settings";
@import "base/reset";
@import "base/navigation";
@import "base/typography";
@import "base/header";
@import "base/footer";

@import "pages/home";
@import "pages/search";
@import "pages/opportunities";

@import "components/buttons";
@import "components/hero";
@import "components/grid-cards";
@import "components/sixtyforty";
@import "components/news";
@import "components/events";
@import "components/jumplinks";
@import "components/fifty-fifty";
@import "components/director";
@import "components/forms";

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

body.ctuir-site {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    color: $bodytext;

    a {
        text-decoration: none;
        @include transition;
    }

    a, a.btn button, input, select {
        &:focus-visible, &:focus {
            outline: 0;
            box-shadow: inset 0 0 0 0.16rem rgb(255 255 255 / 80%), 0 0 0 0.16rem rgb(18 27 31 / 80%);
        }
    }

    #ctuir-main, .umb-block-list {
        padding-bottom: 1px; /*fixes vertical margins on components*/
    }
}

[v-cloak] {
    display: none
}


img {
    max-width: 100%;
}

.p-md-4 {
    @media #{$above768} {
        padding: ($spacer * 2.25) !important;
    }
}

.px-md-4 {
    @media #{$above768} {
        padding-left: ($spacer * 2.25) !important;
        padding-right: ($spacer * 2.25) !important;
    }
}

.comp {
    margin: 60px 0;
}

.comp.color-bg-section {
    margin: 0;
    padding: 60px 0;
}

.flag-pattern {
    height: 46px;
    background-image: url(/images/flag-pattern.png);
    background-repeat: repeat-x;
    width: 100%;
    position: absolute;
    z-index: 4;
}

h2.centered-line-heading {
    text-align: center;
    position: relative;
    margin-bottom: 40px;

    span {
        display: inline-block;
        margin: auto;
        padding: 10px 60px;
        background-color: #fff;
        position: relative;
        z-index: 1;
    }

    &:after {
        content: "";
        display: block;
        position: absolute;
        height: 1px;
        width: 100%;
        top: 50%;
        background-color: $bodytext;
    }
}

.internal-page-title {
    margin: 30px 0;
}

.component-heading {
    margin-bottom: 30px;
    @include flex($justify:space-between);

    span.fa-duotone {
        color: $orange;
        display: inline-block;
        margin-right: 12px;
    }

    a.ctuir-btn {
        align-self: flex-start;
        margin-top: 12px;
    }

    @media #{$above768} {
        margin-bottom: 0;
    }
}

.component-subheading {
    margin: 15px 0 30px;

    @media #{$above768} {
        width: 70%;
    }
}

.highlights-heading {
    margin-bottom: 30px;

    span.fa-duotone {
        color: $orange;
        display: inline-block;
        margin-right: 12px;
    }
}

.ctuir-breadcrumb-section {
    padding: 12px 0;
}

ul.breadcrumbs {
    li {
        display: inline-block;

        a {
            color: $hyperlink;
            font-weight: 700;
        }

        span.current-page {
            display: block;
            color: #808080;
        }

        span[class*="fa fa-angle-right"] {
            padding: 0 8px;
            color: #808080;
            @include transition($sec:.2s);
        }

        &.home {
            a {
                color: $hyperlink;
                font-size: 1.1em;
                margin-right: 4px;
            }
        }

        &:nth-last-child(2) {
            @media #{$below768} {
                display: inline-block;

                a, &.active {
                    padding: 0 4px;
                }

                span[class*="fa fa-angle-right"] {
                    float: left;
                    transform: rotate(180deg);
                    margin: 4px 2px 0 0;
                }
            }
        }

        &:hover, &:focus {
            span[class*="fa fa-angle-right"] {
                transform: scaleX(-1);
            }
        }

        @media #{$below768} {
            display: none;
        }
    }
}

.comp.page-intro-section {
    margin: 60px 0;

    .page-intro {
        p {
            font-size: 1.3em;
            font-weight: 300;
            font-style: italic;
        }
    }
}

img.arrow-pattern {
    opacity: .05;
    @include cover-image;
    mix-blend-mode: multiply;
    left: unset;
    right: -22%;
    z-index: 0;
}

.drop-label {
    position: relative;
    vertical-align: middle;
    display: block;

    .angle-down {
        font-size: 1.2em;
        right: 0;
        top: 0;
        width: 54px;
        height: 100%;
        position: absolute;
        pointer-events: none;
        background-color: #d6d6d6;
        color: $bodytext;
        @include flex($justify:center);
        align-items: center;

        .fa-angle-down {
            
        }
    }

    select.drop-select {
        background-color: white;
        border: 1px solid $lightgrey;
        display: inline-block;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        cursor: pointer;
        vertical-align: middle;
        font-weight: 400;
        font-size: 1em;
        padding: 6px 0px 6px 10px;
        border: 1px solid #d6d6d6;
        width: 100%;
        height: 40px;

        &::-ms-expand {
            display: none;
        }
    }

    &.disabled {
        opacity: .4;
    }
}
