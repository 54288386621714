// IMPORTS
@import "../base/settings";

.comp.page-links-section {
    margin: 40px 0;
    padding: 40px 0;
    background-color: $lightgrey;
    overflow: hidden;
    position: relative;

    .container-lg {
        position: relative;
        z-index: 1;
    }

    nav.page-links {
        a.page-link {
            display: inline-block;
            padding: 10px 40px 10px 0;
            color: $hyperlink;
            border-bottom: 5px solid transparent;

            &:after {
                @include transition();
                display: block;
                content: "";
                position: relative;
                bottom: 0;
                height: 5px;
                background-color: $hyperlink;
                width: 0;
            }

            span.fa-solid {
                @include transition($sec:0.3s);
            }

            span {
                display: inline-block;
                margin-right: 12px;
            }

            &:hover, &:focus {
                &:after {
                    width: 100%;
                }

                span.fa-solid {
                    transform: scaleX(-1);
                }
            }
        }
    }
}

.comp.jump-links-section {
    .jump-links-bar {
        background-color: $darkblue;
        color: #fff;
        position: relative;
        overflow: hidden;
        padding: 12px 0;

        nav.jump-links {
            a.jump-link {
                display: inline-block;
                padding: 10px 40px 10px 0;
                color: #fff;

                &:after {
                    @include transition();
                    display: block;
                    content: "";
                    position: relative;
                    bottom: 0;
                    height: 5px;
                    background-color: #fff;
                    width: 0;
                }

                span.fa-solid {
                    @include transition($sec:0.3s);
                    margin-left: 12px;
                }

                span {
                    display: inline-block;
                    margin-right: 12px;
                }

                &:hover, &:focus {
                    &:after {
                        width: 100%;
                    }

                    span.fa-solid {
                        transform: rotate(90deg);
                    }
                }
            }
        }

        > .container-lg {
            position: relative;
            z-index: 1;
        }

        img.arrow-pattern {
            opacity: .15;
            @include cover-image;
            mix-blend-mode: soft-light;
            left: unset;
            right: -22%;
            z-index: 0;
        }
    }
}