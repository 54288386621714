// IMPORTS
@import "../base/settings";

.search-results-top {
    border-top: 1px solid $lightgrey;
    border-bottom: 1px solid $lightgrey;
    padding: 30px 0;

    .input-group {
        flex-wrap: nowrap;

        .btn.btn-primary {
            height: 100%;
        }
    }
}

.search-results-page {
    padding: 30px 0;

    h2.results-showing {
        margin-bottom: 40px;
        padding-top: 40px;
    }

    .search-results {
        .search-result {
            padding: 20px 0;
            margin: 20px 0;
            border-bottom: 1px solid $lightgrey;

            a.result-title {
                color: $hyperlink;
                font-weight: 700;
                font-size: 1.2em;
            }

            .result-content {
                padding: 20px 0;
                @include flex($wrap:nowrap);

                .result-media {
                    width: 120px;
                    flex-shrink: 0;
                    margin-right: 20px;

                    .result-event-month {
                        text-align: center;
                        font-size: 1.4em;
                        font-weight: 700;
                        color: #fff;
                        background-color: $magenta;
                    }

                    .result-event-day {
                        text-align: center;
                        font-size: 1.4em;
                        font-weight: 700;
                        background-color: $lightgrey;
                    }
                }

                .result-summary {
                    width: 100%;

                    p {

                    }
                }
            }
        }
    }

    .search-crumbs {
        display: inline-block;
        padding: 6px 24px;
        background-color: $lightgrey;

        ul.breadcrumbs {
            li {
                font-size: .8em;
            }
        }
    }
}