// IMPORTS
@import "../base/settings";

.hero-section {
    height: auto;
    max-height: 100%;
    position: relative;
     
    &.home-hero {
        min-height: 645px;

        @media #{$above1200} {
            height: 837px;
            max-height: 85vh;
        }
    }

    &.internal-hero {
        border-bottom: 10px solid $magenta;
    }

    &:before {
        content: "";
        @include cover-image;
        z-index: 1;
        background-color: rgba(0,0,0,.5);
    }

    img, video {
        @include cover-image;
        z-index: 0
    }

    .flag-pattern {
        bottom: 0;
    }

    .container-lg {
        height: 100%;
        position: relative;
        z-index: 2;
    }

    .hero-content {
        padding: 340px 0 80px 0;

        @media #{$above576} {
            padding: 240px 0 80px 0;
        }
    }

    h1, p {
        color: #fff;
    }

    h1 {
        margin-bottom: 20px;

        @media #{$above1200} {
            font-size: 3em;
        }
    }

    p {
        font-size: 1.2em;
        font-weight: 300;
        font-style: italic;
    }

    .hero-ctas {
        margin-top: 30px;

        a.ctuir-btn {
            margin-right: 15px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}