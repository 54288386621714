// IMPORTS
@import "../base/settings";

.comp.grid-cards-section {
    margin: 60px 0;

    a.grid-card {
        display: block;
        overflow: hidden;
        position: relative;
        padding-top: 100%;

        &:before {
            content: "";
            @include cover-image;
            @include transition($sec:.3s);
            z-index: 1;
            background-color: rgba(0,0,0,.5);
        }

        img.grid-card-image {
            @include cover-image;
            @include transition($sec:.3s);
            z-index: 0
        }

        img.cross-icon {
            position: absolute;
            top: 12px;
            left: 12px;
            width: 34px;
            height: 34px;
            display: block;
            z-index: 2;
            @include transition($sec:.3s);
        }

        .grid-card-content {
            text-align: center;
            width: 100%;
            text-transform: uppercase;
            padding: 0 20px;
            color: #fff;
            @include vertical-align(absolute);
            z-index: 2;

            h2, h3, h4, p {
                color: #fff;
            }
        }

        &:focus, &:hover {
            &:before {
                background-color: rgba(191,25,148,.5);
            }

            img.cross-icon {
                transform: rotate(360deg);
            }

            img.grid-card-image {
                transform: scale(1.1);
            }
        }
    }
}

.comp.category-cards-section {
    a.category-card {
        display: block;
        overflow: hidden;

        .color-gradient-strip {
            height: 26px;
            background: linear-gradient(90deg, rgba(211,191,221,1) 0%, rgba(189,62,150,1) 25%, rgba(218,32,50,1) 50%, rgba(242,101,49,1) 75%, rgba(3,73,122,1) 100%);
            background-attachment: fixed;
        }

        .link-title-bar {
            background-color: $bodytext;
            padding: 8px 20px 6px 20px;
            color: #fff;
            @include flex($justify:space-between);
            @include transition;
            align-items: center;

            h3 {
                margin: 0;
                font-family: 'Roboto', sans-serif;
                font-size: 1.1em;
                color: #fff;
            }
        }

        .category-card-image {
            position: relative;
            overflow: hidden;
            padding-top: 32.125%;

            &:before {
                content: "";
                z-index: 1;
                opacity: .3;
                @include cover-image;
                @include transition;
            }

            img {
                @include cover-image;
                z-index: 0;
            }
        }

        &:hover, &:focus {
            .link-title-bar {
                padding: 8px 10px 6px 30px;
            }

            .category-card-image {
                &:before {
                    opacity: 0;
                }
            }
        }
    }

    .col-lg-4:nth-child(1), .col-lg-4:nth-child(4n) {
        .color-gradient-strip, .category-card-image:before {
            background: linear-gradient(90deg, rgba(211,191,221,1) 0%, rgba(189,62,150,1) 100%);
        }    
    }

    .col-lg-4:nth-child(2), .col-lg-4:nth-child(5n) {
        .color-gradient-strip, .category-card-image:before {
            background: linear-gradient(90deg, rgba(189,62,150,1) 0%, rgba(218,32,50,1) 50%, rgba(242,101,49,1) 100%);
        } 
    }

    .col-lg-4:nth-child(3), .col-lg-4:nth-child(6n) {
        .color-gradient-strip, .category-card-image:before {
            background: linear-gradient(90deg, rgba(242,101,49,1) 0%, rgba(3,73,122,1) 100%);
        }  
    }
}