// IMPORTS
@import "settings";

.ctuir-footer {
    position: relative;
    padding: 120px 0 100px 0;
    color: #fff;
    z-index: 1;

    a, h2, h3, h4, p {
        color: #fff;
    }

    &:before {
        content: "";
        @include cover-image;
        z-index: 1;
        background-color: rgba(0,15,26,.8);
    }

    img.footer-bg-image {
        @include cover-image;
        z-index: 0;
    }

    .flag-pattern {
        top: 0;
    }

    .container-lg {
        height: 100%;
        position: relative;
        z-index: 2;
    }

    .footer-links-column {
        margin-bottom: 30px;

        @media #{$above1200} {
            margin-bottom: 0;
        }
    }

    ul.footer-links {
        li {
            a {
                display: inline-block;
                padding: 4px 0;
                border-bottom: 5px solid transparent;

                &:after {
                    @include transition();
                    display: block;
                    content: "";
                    position: relative;
                    bottom: 0;
                    height: 5px;
                    background-color: $magenta;
                    width: 0;
                }

                &:hover, &:focus {
                    &:after {
                        width: 100%;
                    }
                }
            }
        }
    }

    ul.lined-list {
        margin-top: 12px;
        margin-bottom: 30px;

        li {
            padding: 10px 0;
            border-bottom: 1px solid;
            border-color: rgba(255,255,255,0.5);

            span {
                display: inline-block;
                margin-right: 15px;
            }
        }
    }

    a.comment-card-btn {
        font-size: 1.2em;

        span {
            display: inline-block;
            margin-right: 15px;
        }
    }

    .copyright {
        font-size: .9em;
        margin-top: 20px;
    }
}