// IMPORTS
@import "../base/settings";

.comp.news-cards-section {
    margin: 60px 0;
    background-color: $lightgrey;
}

.image-card {
    @include standard-box-shadow($size:10px);
    background-color: #fff;
    max-width: 416px;
    width: 100%;
    height: 100%;
    margin: auto;
    margin-bottom: 30px;

    &.news-card {
        @include flex();
        flex-direction: column;

        .card-image-container {
            border-bottom: 7px solid $orange;
            height: 236px;
            flex-grow: 0;

            img.image-arrow {
                position: absolute;
                display: block;
                width: 30px;
                height: 33px;
                bottom: 0;
                left: 50%;
                margin-left: -15px;
                z-index: 3;
            }
        }

        .card-content {
            /*height: 400px;*/
            flex-grow: 1;
            position: relative;

            p {
                height: 162px;
                font-size: 1.2em;
            }

            h3 {
                margin-bottom: 60px;
                max-height: unset;
            }
        }

        a.ctuir-btn {
            position: absolute;
            bottom: 12px;
            left: 12px;
        }
    }

    &.highlight-card {
        .card-image-container {
            border-bottom: 7px solid $red;
        }

        .card-content {
            background-color: $lightgrey;
            padding-bottom: 20px;
            height: 100%;

            p {
                height: 135px;
                font-size: 1em;
            }
        }
    }

    .card-image-container {
        position: relative;
        padding-top: 55.072%;
        border-bottom: 7px solid $orange;

        img.card-image {
            @include cover-image;
        }

        /*&:before {
            content: "";
            @include cover-image;
            @include transition($sec:.3s);
            z-index: 2;
            background-color: rgba(0,0,0,.5);
            pointer-events: none;
        }*/
    }

    .card-content {
        /*height: 100%;*/
        padding: 12px;
        position: relative;

        a.ctuir-btn {
            /*position: absolute;
            bottom: 12px;
            left: 12px;*/
        }

        .card-details {
            @include flex($justify:space-between);

            span.card-tag {
                text-transform: uppercase;
                font-weight: 600;
                color: $orange;
            }

            span.card-date {
                color: #6f6f6f;
            }
        }

        p {
            overflow: hidden;
            font-weight: 300;
        }

        h3 {
            font-size: 1.4em;
            max-height: 90px;
            overflow: hidden;

            a {
                color: $bodytext;

                &:hover, &:focus {
                    color: $hyperlink;
                }
            }
        }
    }

    &:hover, &:focus {
        /*.card-image-container:before {
            background-color: rgba(0,0,0,0);
        }*/
    }

    @media #{$above991} {
        margin-bottom: 0;
    }
}

.article-top {
    margin-bottom: 40px;

    .article-meta {

    } 
}

.article-body {
    padding-bottom: 60px;

    .article-image {
        max-width: 320px;
        float: left;
        margin: 0 40px 40px 0;

        img {
            display: block;
        }
    }
}

.ctuir-pagination {
    display: flex;
    list-style: none;
    justify-content: flex-start;
    align-items: center;
    margin-top: 40px;

    .pagination-item {
        text-decoration: none;
        display: inline-block;
        padding: 10px 15px;
        margin: 0 5px;
        border: 1px solid $bodytext;
        color: $bodytext;
        border-radius: 4px;
        transition: background-color 0.3s, color 0.3s;

        &.active {
            background-color: $hyperlink;
            color: #fff;
            pointer-events: none;
        }

        &:hover {
            background-color: $hyperlink;
            color: #fff;
        }
    }

    .pagination-prev {
        margin-left: 0;
    }

    .pagination-prev, .pagination-next {
        font-weight: bold;
    }
}